import React from 'react';
import { Container, Button, Typography, Box, Grid, Badge } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';

const LandingPage: React.FC = () => {
  const Header: React.FC = () => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2 }}>
        {/* Logo and Title */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            src="/logo512.png"
            alt="Sports Today Logo"
            style={{ width: 40, height: 40, marginRight: 8, borderRadius: '8px' }}
          />
          <Typography variant="h6" component="div">
            Sports Today
          </Typography>
        </Box>

        {/* Mail link */}
        <a href="mailto:hello@sportstoday.app?subject=Feedback%20Sports%20Today">
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'rgb(254, 215, 9)', // Custom background color
              color: 'rgb(0, 0, 0)', // Custom text color
              borderRadius: '20px',
            }}
          >
            Questions?
          </Button>
        </a>
      </Box>
    );
  };

  const Main: React.FC = () => {
    return (
      <Box sx={{ textAlign: 'center', py: 6 }}>
        <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold' }}>
          Welcome to Sports Today
        </Typography>
        <Typography variant="subtitle1" sx={{ color: 'text.secondary', mt: 2 }}>
          Which of your favourite sports are on today? Find out now!
        </Typography>

        {/* App Store Buttons */}
        <Grid container spacing={2} sx={{ mt: 4, justifyContent: 'center' }}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AppleIcon />}
              href="https://apps.apple.com/app/sports-today/id6677031458"
              target="_blank"
              sx={{
                px: 2,
                color: 'rgb(0, 0, 0)', // Custom text color
                borderColor: 'rgb(0, 0, 0)', // Custom border color

              }}
            >
              Download on Apple Store
            </Button>
          </Grid>
          <Grid item>
            <Badge
              badgeContent={"Coming soon"}
              color="info"
            >
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AndroidIcon />}
                href="https://play.google.com/store" // Replace with actual Play Store link
                target="_blank"
                sx={{
                  px: 2,
                  color: 'rgb(0, 0, 0)', // Custom text color
                  borderColor: 'rgb(0, 0, 0)', // Custom border color
                }}
                disabled
              >
                Download on Google Play
              </Button>
            </Badge>
          </Grid>
        </Grid>
      </Box>
    )
  };

  const Footer: React.FC = () => {
    return (
      <Box sx={{ textAlign: 'center', py: 3, borderTop: 1, borderColor: 'divider', mt: 6 }}>
        <Typography variant="body2" color="text.secondary">
          © {new Date().getFullYear()} Sports Today. All rights reserved. Contact us at{' '}
          <a href="mailto:hello@sportstoday.app?subject=Feedback%20Sports%20Today">
            hello@sportstoday.app
          </a>
          .
        </Typography>
      </Box>
    );
  };

  return (
    <Container maxWidth="md">
      <Header />
      <Main />
      <Footer />
    </Container>
  );
};

export default LandingPage;